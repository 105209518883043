import { writable } from 'svelte/store';
export const spatial = writable({});
export const ages = writable([]);
export const marital = writable({});
export const nationality = writable({});
export const load = () => {
    return Promise.all([
        fetch('/assets/data/spatial.json')
            .then((data) => {
            return data.json();
        })
            .then((json) => {
            spatial.set(json);
        }),
        fetch('/assets/data/ages.json')
            .then((data) => {
            return data.json();
        })
            .then((json) => {
            ages.set(json);
        }),
        fetch('/assets/data/marital.json')
            .then((data) => {
            return data.json();
        })
            .then((json) => {
            marital.set(json);
        }),
        fetch('/assets/data/nationality.json')
            .then((data) => {
            return data.json();
        })
            .then((json) => {
            nationality.set(json);
        })
    ]);
};
